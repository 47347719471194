<template>
  <div class="container pb-5 pt-lg-5 pb-lg-9">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-xl-6">
        <img class="img-fluid mb-5" src="../../assets/img/event/mommy_new.jpg" alt="寵愛媽咪">
        <p class="fw-bold">※ 滿額贈品數量有限，送完為止。</p>
        <p class="fw-bold">身心美 抽獎送_活動辦法：</p>
        <ul class="border-bottom border-dark pb-3">
          <li class="fw-bold">活動時間：2022/4/1-2022/5/31</li>
          <li class="fw-bold">抽獎資格：須於2022/4/1-2022/5/31 23:59前(以訂單付款完成時間為主)，單筆訂單購買Papawash官網商品滿$2,500<span class="fw-normal">(折扣後金額)</span>，並付款完成，即可享有一次抽獎機會，買越多抽獎機會越多。</li>
          <li>抽獎日期：<span class="fw-bold">2022/6/15</span></li>
          <li>中獎公告：獎項將於<span class="fw-bold">2022/6/15</span>隨機抽出，<span class="fw-bold">2022/6/16</span>公布於官方粉絲團，Papawash將於<span class="fw-bold">2022/6/16</span>以電話、<span class="fw-bold">e-mail</span>方式通知中獎者，恕不提供其他方式。</li>
        </ul>
        <p>※ <span class="fw-bold">Papawash</span>保留修改/變更活動與獎項細節之權利。</p>
      </div>
    </div>
  </div>
</template>
